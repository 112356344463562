import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/outsourcing.jpg'

const pageTitle = "Outsourcing";
const pageSlug = "outsourcing";


class Outsourcing extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Increased competition is proving to be a difficult obstacle for most companies, vying for qualified capital and forcing businesses to try to do more with less.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <p>Increased competition is proving to be a difficult obstacle for most companies, vying for qualified capital and forcing businesses to try to do more with less. Leading IT executives to reputable outsourcing partners to rapidly gain a competitive advantage and professional help.</p>
                  <p>Very few outsourcing companies are always trying to do the right thing for their customers. Our solutions for outsourcing drive incredibly high performance levels, estimate resource continuity concerns and lower relevant costs. Our experts are trained to handle a variety of situations.</p>
                  <p>To build a versatile, scalable and efficient relationship, we combine seasoned professionals with our effective management practices. If we support new business strategies or improve applications, our customers are assured that their critical business applications are in good hands. </p>
                  <p>Unlike our competitors, our professionals in IT staffing and outsourcing help our clients plan, develop and deploy custom or packaged business solutions. We understand the importance of close communication with on-site teams. We can guarantee you a reliable and cost-effective service in addition to any other related concerns.</p>
                    
                  <span className="break"></span>
                  <span className="break"></span>
                  <h5>Our Delivery Services</h5>
                  <p>We Offer:</p>
                  <ul>
                    <li>Delivery options</li>
                    <li>Proven methodologies and certified resources</li>
                    <li>Tools, templates, infrastructure and other jump-start project launch accelerators</li>
                  </ul>
                  <h5>Outsourcing Services </h5>
                  <p>We are sure to provide you with a quality and secure partnership of outsourcing. Let us show you how outsourcing IT systems can provide IT services and strategic business priorities with skills and real value-adding solutions. </p>
                  <h5>Management and Maintenance Outsourcing Needs </h5>
                  <p>Our outsourcing services help you resolve your questions about the needs of the projects being done and how it can affect the competitive advantage. We explain in detail these questions and work with you to reduce the total cost of supporting your core business applications. We do this by delivering the following to your company.</p>
                  <ul>
                    <li>Application Support</li>
                    <li>Manual and automated testing assistance </li>
                    <li>IT staffing</li>
                    <li>Application support services</li>
                  </ul>
                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default Outsourcing
